import useExplanations from "./useExplanations"
import Tour from 'reactour'

const Explanations = ({ steps }) => {

    const controller = useExplanations(steps)
    const { closeTour, dismissStep } = controller
    const { isOpen } = controller.state

    return steps.length > 0 ? <Tour
        isOpen={isOpen}
        steps={steps.map(step => {
            if (Array.from(step.selector)[0] === '#');
            else step.selector = `#${step.selector}`
            return step
        })}
        onRequestClose={closeTour}
        getCurrentStep={(curr) => dismissStep(curr)}
    /> : <></>

}
export default Explanations