import { useContext, useEffect } from 'react'
import { ExplanationContext } from '../../App'
import { db } from '../../utilities/db'

function useExplanation(explanationId, content, pageId) {
    const explanationContext = useContext(ExplanationContext)

    useEffect(async () => {
        let pageExplanationDisabled = await db.pagesWithExplanationDisabled.get({
            pageId
        })
        if (pageExplanationDisabled) return
        let explanationIsDismissed = await db.dismissedExplanations.get({
            pageId,
            explanationId: `#${explanationId}`
        })
        if (explanationIsDismissed);
        // else explanationContext.addExplanationStep({
        //     selector: explanationId,
        //     content,
        //     pageId
        // })
    }, [])

}

export default useExplanation