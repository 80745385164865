import { useReducer, useRef } from "react"
import { useHistory } from "react-router-dom"

const OPEN_SEARCH_BOX = 1
const CHANGE_QUERY = 2
const CHANGE_ICON_VISIBILITY = 3
const CHANGE_SEARCH_TYPE = 4

const SEARCH_QUESTIONS = 1
const SEARCH_COUPONS = 2
const SEARCH_QUESTIONS_TAGS = 3
const SEARCH_NAMES = 4

const useSearchbar = () => {
    const history = useHistory()
    const initialState = {
        isOpen: false,
        query: '',
        iconVisibility: false,
        searchType: SEARCH_QUESTIONS
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case OPEN_SEARCH_BOX://value
                return {
                    ...state,
                    isOpen: action.value
                }
            case CHANGE_QUERY://value
                return {
                    ...state,
                    query: action.value
                }
            case CHANGE_ICON_VISIBILITY://value
                return {
                    ...state,
                    iconVisibility: action.value
                }
            case CHANGE_SEARCH_TYPE://value
                return {
                    ...state,
                    searchType: action.value
                }
            default: return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const { isOpen, query, searchType } = state
    const inputBoxFocusRef = useRef()
    const searchIconClick = () => {
        if (!isOpen) {
            inputBoxFocusRef.current.focus()
        } else {
            inputBoxFocusRef.current.blur()
        }
        if (isOpen) changeQuery('')
        dispatch({
            type: OPEN_SEARCH_BOX,
            value: !isOpen
        })
    }

    const changeQuery = (text) => {
        dispatch({
            type: CHANGE_QUERY,
            value: text
        })
    }

    const submit = () => {
        if (query != '') {
            if (searchType == SEARCH_QUESTIONS) history.push(`/questions/search/${query}`)
            else if (searchType == SEARCH_COUPONS) history.push(`/coupons/search/${query}`)
            else if (searchType == SEARCH_QUESTIONS_TAGS) history.push(`/questionsTags/search/${query}`)
            else if (searchType == SEARCH_NAMES) history.push(`/users/search/${query}`)
        }
    }

    const selectCheck = (searchType) => {
        dispatch({
            type: CHANGE_SEARCH_TYPE,
            value: searchType
        })
    }

    return {
        state, searchIconClick, inputBoxFocusRef, changeQuery, submit, selectCheck
    }
}

export default useSearchbar