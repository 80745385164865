import Form from 'react-bootstrap/Form'
const Input = ({ label, children, explanation, required }) => {
    return <Form.Group className="mb-3">
        <Form.Label>{label}{required == true ? <p style={{ color: "red", display: "inline" }}>*</p> : <></>}</Form.Label>
        {children}
        <Form.Text className="text-muted">
            {explanation}
        </Form.Text>
    </Form.Group>
}
export default Input