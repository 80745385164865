import style from './Searchbar.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import useSearchbar from './useSearchbar'

const SEARCH_QUESTIONS = 1
const SEARCH_COUPONS = 2
const SEARCH_QUESTIONS_TAGS = 3
const SEARCH_NAMES = 4

const Searchbar = () => {
    const controller = useSearchbar()
    const { isOpen, query, searchType } = controller.state

    return <form className={style['wrapper']} onSubmit={e => {
        e.preventDefault()
        controller.submit()
    }}
        onMouseEnter={e =>
            controller.searchIconClick()
        }
        onMouseLeave={e =>
            controller.searchIconClick()
        }>
        <div
            className={style['search-box']}

        >
            <div
                className={style["search-btn"]}
                onClick={e =>
                    controller.submit()
                }
            >
                <FontAwesomeIcon icon={faSearch} />
            </div>

            <input className={style["search-text"]}
                type="text"
                placeholder="البحث عن أسئلة, كروت, تصنيفات أو أسماء مستخدمين"
                value={query}
                ref={controller.inputBoxFocusRef}
                onChange={e => {
                    controller.changeQuery(e.target.value)
                }} />
            {
                isOpen ? <form className={style["search-options"]}>
                    <div>
                        <input type="radio" id='questions-radio' value='questions'
                            checked={searchType == SEARCH_QUESTIONS}
                            onChange={e => controller.selectCheck(SEARCH_QUESTIONS)} />
                        <label for='questions-radio'>البحث ضمن الأسئلة</label>
                    </div>
                    <div>
                        <input type="radio" id='cards-radio' value='coupons'
                            checked={searchType == SEARCH_COUPONS}
                            onChange={e => controller.selectCheck(SEARCH_COUPONS)} />
                        <label for='cards-radio' >البحث عن كرت</label>
                    </div>
                    <div>
                        <input type="radio" id='questions-tags-radio' value='questionsTags'
                            checked={searchType == SEARCH_QUESTIONS_TAGS}
                            onChange={e => controller.selectCheck(SEARCH_QUESTIONS_TAGS)} />
                        <label for='questions-tags-radio' >البحث عن تصنيفات</label>
                    </div>
                    <div>
                        <input type="radio" id='names-radio' value='names'
                            checked={searchType == SEARCH_NAMES}
                            onChange={e => controller.selectCheck(SEARCH_NAMES)} />
                        <label for='names-radio' >البحث عن مستخدمين</label>
                    </div>
                </form> : ""
            }
        </div>
    </form>
}
export default Searchbar