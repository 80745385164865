import React from 'react'
import sign from './SignIn.module.css';
import useSignIn from './useSignIn';
import TextInput from '../components/input/TextInput';

const FILL_FIELD = 1

function SignIn() {
    const controller = useSignIn()

    return (
        <div className={sign["login-page"]}>
            <div className={sign["form"]}>
                <form className={sign["login-form"]} onSubmit={controller.handleSubmit}>
                    <input type="text" placeholder="username"
                        value={controller.state.fields.username}
                        onChange={e =>
                            controller.dispatch({
                                type: FILL_FIELD,
                                field: "username",
                                value: e.target.value
                            })
                        } />
                    <TextInput password label="كلمة السر" required value={controller.state.fields.password}
                        onChange={e =>
                            controller.dispatch({
                                type: FILL_FIELD,
                                field: "password",
                                value: e.target.value
                            })
                        } />
                    <button>تسجيل الدخول</button>
                </form>
            </div>
        </div>
    )
}
export default SignIn