export const AddQuestionsPageId = 1
export const AdminComponentId = 2
export const AdminPageId = 3
export const BrowseRootTermsComponentId = 4
export const BrowseTermsPageId = 5
export const NavbarComponent = 6
export const SidebarComponent = 7
export const CouponsTypesPageId = 8
export const CouponSearchPageId = 9
export const DashboardPageId = 10
export const QuestionComponent = 11
export const QuestionsPageId = 12