import { useContext, useReducer } from 'react'
import { AppContext, DialogueContext, PageContext } from "../../App"
import Network from '../../utilities/Network'
import axios from 'axios'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import TextInput from '../input/TextInput'
import TextArea from '../input/TextArea'

const MODIFY_NOTIFICATION = 8
const REFRESH = 7

const UseSidebar = () => {
    const dialogueContext = useContext(DialogueContext)
    const pageContext = useContext(PageContext)
    const appContext = useContext(AppContext)

    function isLoggedIn() {
        return localStorage.getItem("admin") != null
    }

    const initialState = {
        notification: {
            title: '',
            body: ''
        },
    }

    const showNotificationDialogue = (title, body) => {
        dialogueContext.editDialogue({
            visible: true,
            children: <Button onClick={e => {
                saveNotification(title, body)
                // showNotificationDialogue('', '')
                dispatch({
                    type: MODIFY_NOTIFICATION,
                    param: "title",
                    value: ''
                })
                dispatch({
                    type: MODIFY_NOTIFICATION,
                    param: "body",
                    value: ''
                })
            }
            } >إرسال</Button>,
            content: <Form>
                <TextInput label="عنوان الإشعار"
                    value={title}
                    required
                    onChange={e => {
                        showNotificationDialogue(e.target.value, body)
                    }} />
                <TextArea label="محتوى الإشعار"
                    required
                    value={body}
                    onChange={e => {
                        showNotificationDialogue(title, e.target.value)
                    }} />
            </Form>
        })
    }


    const reducer = (state, action) => {
        switch (action.type) {
            case REFRESH:
                return {
                    ...state
                }
            case MODIFY_NOTIFICATION://param value
                var n = state.notification;
                n[action.param] = action.value;
                if (n.title == '' && n.body == '') dialogueContext.hideDialogue()
                else showNotificationDialogue(n.title, n.body)
                return {
                    ...state,
                    notification: {
                        ...state.notification,
                        [action.param]: action.value
                    }
                }
            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const sendNotification = (title, notificationBody) => {

        const body = {
            to: `/topics/all`,
            // data:{
            //   sql:{
            //     sql:["delete from questionTags","delete from terms"]
            //   }
            // }
            notification: {
                title: title,
                body: notificationBody
            }
        }

        var headers = {
            "Content-Type": "application/json",
            "Authorization": `key=${appContext.value.getFCMServerKey()}`
        }
        pageContext.showLoading()
        axios.post('https://fcm.googleapis.com/fcm/send', body, { headers: headers })
            .then(respons => {
                pageContext.showLoading(false)
                pageContext.showToast({
                    type: "success",
                    "title": "تم إرسال الإشعار",
                    "description": ""
                })
            }
            )
            .catch(err => {
                pageContext.showLoading(false)
                //console.log(err.response.data)
                for (var c = 0; c < Object.keys(err).length; c++)console.log(Object.keys(err)[c], err[Object.keys(err)[c]]);
            })
    }

    const saveNotification = (title, body) => {
        new Network({
            url: "/sendNotificationToAllDevices.php",
            method: "post",
            context: pageContext,
            dialogueContext: dialogueContext,
            title: `إرسال الإشعار ${title} إلى كل المستخدمين`,
            params: {
                'title': title,
                'body': body
            },
            onConnectionSucceed: (data) => sendNotification(title, body)
        }).invoke();
    }
    return {
        state: state,
        isLoggedIn: isLoggedIn,
        showNotificationDialogue: showNotificationDialogue,
        dispatch: dispatch
    }
}
export default UseSidebar