import React, { useContext } from 'react';
import { PageContext } from '../../App';
import style from './Toast.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import UseToast from './useToast';

const Toast = (props) => {
    const { toast, position } = props;

    const controller = UseToast(props)
    const pageContext = useContext(PageContext)

    return <div className={`${style['notification']} ${style['toast']} ${style[toast.type]}  ${controller.state.dismissed ? style[position + "-fade"] : style[position]}`}>
        <button className={style.button} onClick={e => {
            pageContext.hideToast(toast.id)
        }}>
            X
        </button>
        <div className={style["notification-image"]}>
            {
                toast.type == "success" ? <FontAwesomeIcon className={style['img']} icon={faCheck} /> :
                    <></>
            }
        </div>
        <div>
            <p className={style["notification-title"]}>{toast.title}</p>
            <p className={style["notification-message"]}>{toast.description}</p>
        </div>
    </div>

}
export default Toast;