import { useContext } from 'react'
import style from './Sidebar.module.css'
import { AppContext, DialogueContext, RTLContext, SideBarContext } from '../../App'
import BrowseRootTerms from '../../BrowseTerms/browseRootTerms'
import { NavLink } from 'react-router-dom';
import Privilage from '../../privilage';
import { faBookOpen, faFlag, faUserCog, faBars, faCommentAlt, faDoorOpen, faBell, faTag, faBusinessTime } from "@fortawesome/free-solid-svg-icons"
import SidebarItem from '../menu_item/MenuItem'
import UseSidebar from './useSidebar'
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import Explanation from '../Explanation/Explanation';
import { SidebarComponent } from '../../components';

const REFRESH = 7

function Sidebar() {
    const appContext = useContext(AppContext)
    const rtlContext = useContext(RTLContext)
    const sidebarContext = useContext(SideBarContext)
    const dialogueContext = useContext(DialogueContext)
    const controller = UseSidebar()

    const customStyle = {}
    if (rtlContext) {
        if (sidebarContext.value) customStyle.right = 0;
        else customStyle.right = -225
    }
    else {
        if (sidebarContext.value) customStyle.left = 0
        else customStyle.left = -225
    }
    return <div className={style["sidebar"]} style={customStyle} >
        <div className={style["profile"]}>
            <img src={appContext.value.getIcon()} alt="profile_picture" />
            <h3>{appContext.value.getName()}</h3>
        </div>
        {
            controller.isLoggedIn() && sidebarContext.value === true ?
                <ul>
                    <Explanation explanationId='browseTerms'
                        pageId={SidebarComponent}
                        content='تصفح المواد الدراسية لتعديل محتوياتها وإضافة أسئلة أو تصنيفات وغيرها'>
                        <SidebarItem
                            title="تصفح المواد"
                            icon={faBookOpen}
                            onClick={() => dialogueContext.editDialogue({ visible: true, content: <BrowseRootTerms /> })}
                        />
                    </Explanation>
                    <Explanation explanationId='questionsTags'
                        pageId={SidebarComponent}
                        content='تصفح التصنيفات وإضافة تصنيفات فرعية وعرض الأسئلة الخاصة بتصنيف معين وإضافة فقرات للتصنيفات'>
                        <NavLink to="/questionsTags">
                            <SidebarItem title="التصنيفات" icon={faTag} />
                        </NavLink>
                    </Explanation>
                    <Explanation explanationId='reports'
                        pageId={SidebarComponent}
                        content='استعراض التبليغات والرد عليها'>
                        <NavLink to="/reports">
                            <SidebarItem title="التبليغات" icon={faFlag} />
                        </NavLink>
                    </Explanation>
                    <Privilage privilageId="8">
                        <Explanation explanationId='admins'
                            pageId={SidebarComponent}
                            content='استعراض المستخدمين في اللوحة، وتعديل بياناتهم وصلاحياتهم والأقسام التي يسمح لهم بتصفحها وحذفهم'>
                            <NavLink to="/admins">
                                <SidebarItem title="المديرين" icon={faUserCog} />
                            </NavLink>
                        </Explanation>
                    </Privilage>
                    <Privilage privilageId="11">
                        <Explanation explanationId='cards'
                            pageId={SidebarComponent}
                            content='استعراض الكروت، الكروت المفعلة اليوم، الكروت الخاصة بنوع معين، إغلاق الدورة المالية، استعراض إحصائيات عن المبيعات'>
                            <NavLink to="/couponsTypes">
                                <SidebarItem title="الكروت" icon={faBars} />
                            </NavLink>
                        </Explanation>
                    </Privilage>
                    <Privilage privilageId="17">
                        <Explanation explanationId='telegramQuestions' pageId={SidebarComponent}
                            content='استعراض وتعديل الأسئلة الشائعة التي تظهر في بوت التلغرام'>
                            <NavLink to="/telegramQuestions">
                                <SidebarItem title="أسئلة بوت التلغرام" icon={faTelegram} />
                            </NavLink>
                        </Explanation>
                    </Privilage>
                    <Privilage privilageId="5">
                        <Explanation explanationId='sendNotification' pageId={SidebarComponent}
                            content='إرسال إشعار جماعي لكل مستخدمي التطبيق'>
                            <SidebarItem title="إرسال إشعار" icon={faCommentAlt}
                                onClick={() => {
                                    controller.showNotificationDialogue('', '')
                                }} />
                        </Explanation>
                    </Privilage>
                    <Privilage privilageId="5">
                        <Explanation explanationId='sentNotifications' pageId={SidebarComponent}
                            content='استعراض الإشعارات التي تم إرسالها إلى المستخدمين، بما فيها الإشعارات الجماعية، الإشعارات المرسلة لمستخدمي قسم دراسي معين، والإشعرات المرسلة لمستخدم معين'>
                            <NavLink to="/notifications">
                                <SidebarItem title="الإشعارات المرسلة" icon={faBell} />
                            </NavLink>
                        </Explanation>
                    </Privilage>
                    <Explanation explanationId='delayedRequests' pageId={SidebarComponent} content='جميع العمليات من رفع بيانات إلى السيرفر أو حذف بيانات أو تعديل بيانات والتي تم تأجيلها من قبلك'>
                        <NavLink to="/delayedRequests">
                            <SidebarItem title="العمليات المؤجلة" icon={faBusinessTime} />
                        </NavLink>
                    </Explanation>
                    <NavLink to="/">
                        <SidebarItem title="خروج" icon={faDoorOpen} onClick={e => {
                            localStorage.clear();
                            controller.dispatch({ type: REFRESH })
                        }} />
                    </NavLink>
                </ul> : <></>
        }
    </div >
}
export default Sidebar