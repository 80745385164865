import { useReducer, useEffect, useContext } from "react"
import { PageContext } from '../../App';

const DISMISS_ANIMATION = 1
const UseToast = (props) => {
    const { toast } = props;
    const pageContext = useContext(PageContext)

    const reducer = (state, action) => {
        switch (action.type) {
            case DISMISS_ANIMATION:
                console.log(state);
                return {
                    ...state,
                    dismissed: true
                }
            default: return state
        }
    }

    const [state, dispatch] = useReducer(reducer, toast)

    useEffect(() => {
        function setDismissTimeout() {
            setTimeout(() => {
                dispatch({
                    type: DISMISS_ANIMATION,
                })
                setTimeout(() => {
                    pageContext.hideToast(toast.id)
                }, 700)
            }, 2500);
        }
        setDismissTimeout()
        // eslint-disable-next-line
    }, [toast]);

    return {
        state: state
    }
}
export default UseToast