import style from './MenuItem.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { RTLContext } from '../../App'
import { useContext } from 'react'

function MenuItem({ title, icon, onClick = () => { }, active = false }) {
    const rtlContext = useContext(RTLContext)
    const customStyle = {}
    if (rtlContext) customStyle.textAlign = "right"
    else customStyle.textAlign = "left"
    return <li className={active ? style.active : ''} style={customStyle} onClick={e => onClick()}><span className={style.icon}><FontAwesomeIcon icon={icon} /></span>
        <span className={style.item}>{title}</span></li>
}
export default MenuItem