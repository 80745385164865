class AppConfig {
    name;
    FCMServerKey;
    icon;
    color;
    id;

    fromMap = (map) => {
        this.id = map.id
        this.name = map.name
        this.FCMServerKey = map.FCMServerKey
        this.icon = map.icon
        this.color = map.color
        return this;
    }

    getName = () => this.name
    getFCMServerKey = () => this.FCMServerKey
    getIcon = () => this.icon
    getColor = () => this.color
    getId = () => this.id
}

export default AppConfig