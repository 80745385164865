import React from 'react'
import Input from './Input'
import Form from 'react-bootstrap/Form'

const TextArea = ({ label, value, onChange, explanation, required = false, style, className, rows }) =>
    <Input label={label} explanation={explanation} required={required} >
        <Form.Control
            as="textarea"
            className={className}
            style={style}
            required={required}
            value={value}
            rows={rows}
            placeholder={`أدخل ${label}`}
            onChange={onChange}
        />
    </Input>

export default TextArea