import { useContext, useReducer } from 'react'
import { db } from '../../utilities/db'
import { useEffect } from 'react'
import { ExplanationContext } from '../../App'

const CLOSE_TOUR = 1
const MODIFY_STEPS_SELECTOR = 2

function useExplanations(steps) {

    const explanationsContext = useContext(ExplanationContext)

    const initialState = {
        isOpen: true,
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case CLOSE_TOUR:
                return {
                    ...state,
                    isOpen: false
                }
            case MODIFY_STEPS_SELECTOR:
                // let modifiedSteps = steps.map(function (step) {
                //     if (step.selector[0] !== '#') step.selector = `#${step.selector}`
                //     return step
                // })
                return {
                    ...state, isOpen: true
                }
            default: return state
        }
    }

    const closeTour = () => {
        dispatch({ type: CLOSE_TOUR })
        explanationsContext.closeTour()
    }


    const dismissStep = async (count) => {
        let step = steps[count]
        let explanationIsDismissed = await db.dismissedExplanations.get({
            pageId: step.pageId,
            explanationId: step.selector
        })
        if (explanationIsDismissed);
        else db.dismissedExplanations.add({
            pageId: step.pageId,
            explanationId: step.selector
        })
    }

    useEffect(() => {
        dispatch({ type: MODIFY_STEPS_SELECTOR })
    }, [steps])

    const [state, dispatch] = useReducer(reducer, initialState)

    return {
        state, closeTour, dismissStep
    }
}

export default useExplanations